import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import { Title, Indent } from "src/uikit"
import Button from "src/newUikit/Button"
import { generateUrl } from "src/common/helpers"

import Issue from "./components/Issue"
import * as S from "./styled"

const Issues = ({ title, posts, linkName, ctaBtnName, onCtaClick }) => {
  const intl = useIntl()

  return (
    <S.Container>
      <div className="inner">
        <Indent lg={25} md={0} sm={0} />
        <Title>{title}</Title>
        <Indent lg={64} md={40} sm={40} />
        <div className="issues-container">
          {posts.map(post => {
            const url = generateUrl(`blog/${post.blogPostId}`, intl.locale)

            return (
              <div key={post.blogPostId} className="issue-container">
                <Issue
                  title={post.title}
                  description={post.bpIntroduction.json}
                  linkName={linkName}
                  url={url}
                />
              </div>
            )
          })}
        </div>
        <Indent lg={8} md={0} sm={0} />
        <div className="attachment-container">
          <Button size="large" inverse color="purple" onClick={onCtaClick}>
            {ctaBtnName}
          </Button>
        </div>
      </div>
      <Indent lg={0} md={-110} sm={-120} />
    </S.Container>
  )
}

export default Issues
