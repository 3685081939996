/**
 * Generates URL based on the new structure pattern:
 * - Russian locale: adds /ru-ru/ prefix
 * - All other locales: uses root path
 * 
 * @param {string} path - The path without locale prefix
 * @param {string} locale - The current locale
 * @returns {string} - The generated URL
 */
export const generateUrl = (path, locale) => {
  // Remove leading slash if present
  const cleanPath = path.startsWith('/') ? path.substring(1) : path;
  
  // Check if this is Russian locale
  const isRussian = locale === 'ru-ru' || locale === 'ru';
  
  // Generate URL based on locale
  return isRussian ? `/ru-ru/${cleanPath}` : `/${cleanPath}`;
};

export default generateUrl; 